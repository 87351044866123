:root{
  --primary-color1: #344f45;
  --primary-color2: #dd9933;
  --primary-color3: blue;
  --primary-text-color1: #344f45;
  --primary-text-color2: #dd9933;
  --primary-text-color3: black;
  --primary-text-color3: white;
  --primary-text-color4: rgb(0, 0, 0, 0.7);
  --primary-text-error: red;
}



.footer-grid-cell{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between; /* Push content to the top and bottom */
    height: 100%; /* Make sure the grid cell takes the full height */
  
}

.footer {
  display: flex;
  justify-content: flex-end; 
  align-items: center;
  background-color: #fff;
  color: black;
  width: 100%;
  max-height: 20px;
  padding-right: 40px;
  align-self: flex-end; /* Align the footer to the bottom of the grid cell */
}

.navbar{
  border-radius: 0;
  width: 100%;
  display: flex;
  justify-Content: space-between;
  align-items: center;
  margin-right: 0;
  background-color: white;
  max-height: 70px;
}

.navbar-logo{
  height: 70px;
  padding: 10px 70px
}

.navbar-button{
  height: 40px;
}

.navbar-container{
  margin-right: 0;
  margin-right:30px
}

.payment-screen{
  justify-Content: center;
  align-Items: center;
  background-Color:var(--primary-color1);
  height: 85vh
}

.payment-image{
  max-height: 40vh;
  max-width: 150px;
}

.payment-price{
  color: #000;
  font-size: 20px;
  font-weight: bold;
}

.login-page {
  background-color: var(--primary-color1);
  height: 100vh;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px 

}

.login-page-intro-text-box{

  justify-content: center;
  align-items: center;
  color: #000;
  font-size: 20px;
  font-weight: bold;
  margin: 50px 50px 50px 60px;
  background-color: #fff;
  /* height: fit-content; */
  border-radius: 50px;
  max-height: 550px;

}

.login-page-intro-text{
  color: #000;
  font-size: 20px;
  margin: 30px 30px 30px 60px;
  justify-content: center;
  align-items: center;

}

.login-page-intro-header{

  color: #000;
  font-size: 40px;
  font-weight: bold;
  margin: 30px 20px 20px 30px;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.login-page-inputs-containers{
  display: flex;
  flex-direction: column;
  margin: 20px 20px 0px 0px;
  max-width: 250px;
}

.login-page-headers{
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  text-shadow: 5px 5px 5px rgba(0, 0, 0, 1);

}

.login-page-inputs{
  display: flex;
  margin: 20px 0px 0px 20px;
}

.login-page-buttons{
  margin: 20px 0px 0px 0px;
  display: flex;
  justify-content: flex-end;
}

.login-page-error{
  color: var(--primary-text-error);
  margin-left: 20px;
}

.survey-grid{
  display: flex;
  flex-direction: column;
}

.survey-container{
  display:flex;
  flex-direction: column;
  height: 80vh;
  min-height: 70vh;
  background-color: var(--primary-color1);
  width:100%
}

.survey-section-header{
  display:flex;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  text-shadow: 5px 5px 5px rgba(0, 0, 0, 1);
  align-items: flex-start;
  margin-left: 50px;
}

.survey-question-header{
  display:flex;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  text-shadow: 5px 5px 5px rgba(0, 0, 0, 1);
  align-items: flex-start;
  margin-left: 70px;
}

.survey-question-text{
  display:flex;
  color: #fff;
  font-size: 20px;
  height: 80vh;
  align-items: flex-end;
  margin: 30px 30px 30px 50px;
  justify-content: flex-start ;
  height: 10vh;
}

.survey-responses{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 70px 50px 20px 70px;
  color: #fff;
  font-size: 17px;
  height: 15vh;
}

.survey-next-button{
  display: flex;
  justify-content: flex-end;
  margin: 20px 20px 20px 20px;
}

.question-fade {
  opacity: 1;
  transition: opacity 0.5s ease-in-out; /* Adjust the duration and timing function as needed */
  height: auto; /* To allow for dynamic content height */
}
.question-fade.hidden {
  opacity: 0;
  height: 0;
  overflow: hidden;
}

.report-header-container{
  display: flex;
  background-color: var(--primary-color1);
  border-radius: 10px;
  padding: 10px;
  margin-top: 30px;

}

.report-header-title{
  display: flex;
  color:white;
  padding-left : 20px;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;

}

.report-header-text{
  display: flex;
  color:white;
  padding-left : 20px;
  font-size: 15px;
  /* font-weight: bold; */

}

.report-header-disclaimer{
  display: flex;
  color:white;
  padding-left : 20px;
  font-size: 10px;
  font-weight: bold;

}

.report-header-name{
  color:white;
  padding-left : 20px;
}

.report-header-date{
  color:white;
  padding-left: 20px;
}

.report-body-header-container{
  display: flex;
  /* background-color: #e0cccc; */
  background-color: var(--primary-color1);
  border-radius: 10px;
  padding: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
}

.report-body-header-title{
  display: flex;
  color:white;
  padding-left : 20px;
  font-size: 20px;
  white-space: pre;
  margin-bottom: 5px;
}

.report-body-header-text{
    display: flex;
    color:white;
    padding-left : 20px;
    font-size: 15px;
    white-space: break-spaces;
    margin-bottom: 5px;

    
;}

.report-section-header{
  display:flex;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  align-items: flex-start;
  margin-left: 50px;
  padding-top: 20px;
}

.pos{
  color:var(--primary-text-color1)
}

.warning{
  color:var(--primary-text-color4)
}
.neg{
  color:var(--primary-text-error)
}

.link{
  color:var(--primary-text-color2);
  cursor: pointer;
  text-decoration: underline;

}

.primary-text-color2{
  color:var(--primary-text-color2);
}








